<template>
  <v-app > 
    <Sidebar/>
   <v-flex class="alltheitens">
      <v-row class="container mx-auto">
        <v-row cols="12" class="row mx-auto justify-center d-flex">
          <v-col cols="12" class="mx-auto justify-center d-flex col-md-4">
            <v-card  shaped class=" cards-padrao c-azul">
              <v-card-title class="text-center mx-auto" v-if="valor_total >= 1">
              <h2 class="text-center mx-auto">Total</h2>
              </v-card-title>
              <v-card-title v-if="valor_total >= 1">
                <h3 class="text-center mx-auto"> R$ {{valor_total}}</h3>
              </v-card-title>
              <v-card-title v-else>
              <h2 class="text-center mx-auto">Total</h2><br>
              <h6 class="text-center mx-auto">Nenhum valor encontrado</h6>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="12" class="mx-auto justify-center d-flex col-md-4">
            <v-card  shaped class="cards-padrao c-azul">
              <v-card-title class="text-center">
              <h3 class="text-center mx-auto">Total antecipar</h3>
              <h2 v-if="valor_receber > 0"> R${{valor_receber}} </h2>
              <h6 v-else>Nenhum valor encontrado</h6>
              </v-card-title>
              <v-card-actions>
                <v-btn dark small
                @click.stop="antecipar=true">
                Solicitar antecipação
                </v-btn>
              </v-card-actions>
          <SolicitarAntecipacao :visible="antecipar" @close="antecipar=false" :valor_total ="valor_transfer"/> 
            </v-card>
          </v-col>
    
          <v-col cols="12" class="mx-auto justify-center d-flex col-md-4">
            <v-card  shaped class="cards-padrao c-azul">
              <v-card-title class="text-center">
              <h3>Transferência</h3>
              <h5 >Saldo:
                  <b v-if="valor_total >0">R${{valor_total}}</b>
                  <b v-else>R$ 00,00</b>
                </h5>
                
              </v-card-title>
              <v-card-actions class="justify-center mx-auto">
                <v-btn dark small class="mt-5"
                >
                <!-- @click.stop="transferir=true" -->
                Solicitar Transferência
                </v-btn>
              </v-card-actions> 
                <SolicitaTransfer :visible="transferir" @close="transferir =false" :valor_total ="valor_total"/>

            </v-card>
          </v-col>
        </v-row>
      </v-row>
      
      <v-row  class="container mx-auto">
        <v-col cols="12">
          <v-card  shaped class="c-azul bilheteria">
            <v-card-title class="text-center">
             <h2 class="text-center mx-auto">Vendas</h2>
            </v-card-title>
           <v-row>                        
              <v-col class="card-text">
                <template>
                  <div >
                    <v-col class="justify-left mx-auto text-center">
                      <v-select 
                            class="uso-mascara " 
                            :items="eventos" dark
                            item-text="name" item-value="_id"
                            v-model="selectEvent" @change="GetItens()"
                            label="Evento" outlined
                            required>
                      </v-select>                    
                    </v-col>
                                    
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row cols="12">
              <div class="text-circle">
                <h2 class="text-left ml-5 pa-2">Bilheteria</h2>
              </div>
              <v-row  v-if="loadingTicksts == false">
                <v-col cols="4" class="mt-5">
                  <h3 class="text-center ml-10 mt-10"> Quantidade vendida: </h3>
                  <h1 class="text-center ml-10 mt-5" v-if="this.ticketsTotal > 0"> {{this.ticketsSell}} / {{this.ticketsTotal}}</h1>
                  <h4 class="text-center ml-10 mt-5" v-else> Nenhum valor encontrado, <br> selecione um evento</h4>

                </v-col>
                <v-col cols="8">
                  <div class="mx-auto justify-center"> 
                    <div id="chart" >
                      <apexchart type="radialBar" height="250" 
                      :options="chartOptions2" :series="[porcentagem_tickets]"></apexchart>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-card-text>
                <v-container style="height: 200px;" v-if="loadingTicksts == true">
                    <v-row 
                    class="fill-height"
                    align-content="center"
                    justify="center"
                        >
              <v-col class="justify-center mx-auto text-center"  cols="6">
                <img  class="mb-3" src="@/assets/logo.png">
                <h5> CARREGANDO...</h5>
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="8"
                ></v-progress-linear>
              </v-col>
            </v-row>
               </v-container>
              </v-card-text>
            </v-row>
            <v-row class="mb-5">
              <div class="text-circle">
                <h2 class="text-left ml-5 pa-2">Produtos</h2>
              </div>              
              <v-row v-if="loadingProducts == false">
                <v-flex   v-for="item in products" class="justify-center mx-auto mt-2" :key="item._id">
                <v-card width="90%" class="justify-center mx-auto mt-2 bilheteria-card">
                   <v-divider></v-divider>

                    <v-col md="12" cols="12" class=" justify-center  text-center mx-auto">
                        <v-text-field 
                        type="text" label="Nome:" outlined
                        v-model="item.name" readonly>
                        </v-text-field>
                    </v-col>
                        
                    <v-col class="card-text">
                        <v-row class="justify-center mx-auto text-center">
                            <div class="card-img">
                                <img :src="`${item.photo.location}`">
                            </div>
                               
                               
                            <v-col>
                                <v-text-field 
                                label="Quantidade Total:"
                                v-model="item.qnt" readonly>
                                </v-text-field>
                           </v-col>
                           <v-col>
                              <v-text-field 
                              label="Quantidade Vendida:"
                              v-model="item.sell" readonly>
                              </v-text-field>
                            </v-col>
                            </v-row>
                        </v-col>
                </v-card>
                </v-flex>
              </v-row>
              <v-card-text>
                <v-container style="height: 200px;" v-if="loadingProducts == true">
                    <v-row 
                    class="fill-height"
                    align-content="center"
                    justify="center"
                        >
              <v-col class="justify-center mx-auto text-center"  cols="6">
                <img  class="mb-3" src="@/assets/logo.png">
                <h5> CARREGANDO...</h5>
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="8"
                ></v-progress-linear>
              </v-col>
            </v-row>
               </v-container>
              </v-card-text>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-flex>
  </v-app>
</template>

<script>
import Sidebar from '@/components/Sidebar/Sidebar.vue'
import {showError} from '@/global'
import { app, http } from '@/server'
import { replaceUrl } from '@/functions/helpers'

import SolicitarAntecipacao from '@/components/Financeiro/SolicitaAntecipacao'
import SolicitaTransfer from '@/components/Financeiro/SolicitaTransfer.vue'
export default {
  name: 'Financeiro',
  components: {
    SolicitarAntecipacao,
    SolicitaTransfer,
    Sidebar
  }, 
  data(){
    return {
      antecipar:false,
      transferir:false,
      selectEvent: null,
      porcentagem_tickets: 0,
      dialog:false,
      close: false,
      ticketsTotal:null,
      ticketsSell: null,
      eventos: [ ],
      series: [0],
      valor_receber: 0,
      loadingTicksts : false,
      products: {},
      historico_transferencia: 0,
      valor_total: '',
      loadingProducts: false,
      valor_transfer: 0,
      chartOptions2: {
        width: 100,
        height: 'auto',
        type: 'radialBar',
        colors:['#6dbfeb'],
        plotOptions: {
         radialBar: {
            hollow: {
              size: '70%',
            },  
          },
        },
       labels: ["Vendidos"],
      },

    }
  },
  mounted() {
    document.title = 'Financeiro'
    this.GetEventos()
    this.GetMoney()
  },
  methods:{
    GetEventos() {
      http.request(app.getEventos)
      .then(res => {
        this.eventos = res.data
      })
      .catch((err)=>{
        showError()
      })
    },
    GetMoney() {
      http.request(app.getMoney)
      .then(res => {
        this.valor_total = res.data.body.totalBalance
      })
      .catch((err)=>{
        showError()
      })
    },
    GetItens() {
       this.GetProducts()
       this.GetTickets()
    },
    GetTickets() { 
      this.loadingTicksts = true
      this.zeroAgain()
      console.log('entrou aqui')
      const url = app.getTickets.url
      app.getTickets.url = replaceUrl(url, '{event_id}', this.selectEvent)
      http.request(app.getTickets)
      .then(res => {
        app.getTickets.url = '/promoter/selltickets/{event_id}'
        this.ticketsTotal = res.data.total
        this.ticketsSell = res.data.pay
        this.calcPorcentagem()
        this.loadingTicksts = false
      })
      .catch((err)=>{
        app.getTickets.url = '/promoter/selltickets/{event_id}'
        showError()
        console.log(err)

      })
    },
    GetProducts() {
      this.loadingProducts = true
      this.products ={}
      const url = app.getProducts.url
      app.getProducts.url = replaceUrl(url, '{event_id}', this.selectEvent)
      http.request(app.getProducts)
      .then(res => {
        app.getProducts.url = '/promoter/sellproducts/{event_id}'
        this.products = res.data.details
        this.loadingProducts = false
      })
      .catch((err)=>{
        showError()
        console.log(err)

      })
    },
    calcPorcentagem() {
      const vendidos =this.ticketsSell 
      const total = this.ticketsTotal
      const porcetagem_vendida = `${(vendidos / total) * 100}`
      this.porcentagem_tickets = Math.round(porcetagem_vendida)
      console.log(this.porcentagem_tickets)
    },
        zeroAgain(){
      this.porcentagem_tickets = 0
      this.ticketsTotal = null
      this.ticketsSell = null
    }
  }
}
</script>
<style scoped>
.cards-padrao{
  text-align: center;
  width: 250px;
  min-height: 150px;
  padding: 10px;
}
/* @media (min-width: 600px){
.cards-padrao{
  margin-left:-50px !important;
  margin-right: 50px !important;
}

} */
.c-azul{
  border-right: solid 10px #6dbfeb !important;
  border-bottom: solid 10px #6dbfeb !important;
  color:#6dbfeb !important;
}
.text-circle{
  border-radius: 45px;
  margin-left: -5px;
  background: #120222 !important;
  max-height: 50px;
}
.bilheteria{
  background: #726a6a !important;
}
.bilheteria-card{
  background: #b9a5a5 !important;
}
.card-img{
  height: 100px;
  margin: auto;
  width: 100px;
  border-radius: 22px;
  border: 2px solid #D356F3;
  color: #D356F3;
  margin-bottom: 5px;
}
.card-img img{
    height: 100%;
    width: 100%;
    border-radius: 20px;
}
</style>