<template>
<v-dialog 
  v-model="show" max-width="600px"
  transition="dialog-bottom-transition">
  <v-stepper v-model="e1" style="background: #000; opacity:0.9" dark><!-- background: #120222;-->
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1" @click=" e1 =1"
      >
        Valor
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2" @click=" e1 = 2"
      >
        Dados bancários 
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">
        Transferir
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">

       <v-container  class="justify-center mx-auto text-center" style="height: 300px;">
          <v-row class="justify-center mx-auto text-center">
            <v-col class="justify-center mx-auto text-center mt-5">
              <h3>Valor disponível para transerir:</h3>
              <div class="transfer-value mt-3 mb-3">
              <h2 v-if="valor_total > 0">{{valor_total}}</h2>
              <h2 v-else>R$ 0,00</h2>
              </div>
              <h4>Qual valor você deseja transerir?</h4>
              <v-row class="box-input"> 
              <v-text-field class="mt-3 box-input"
                type="float" dark v-model="transferir.valor"
                outlined 
                :rules="[v => !!v || 'Valor é obrigatório']"
                clearable  dense label="Valor"
               required></v-text-field>
              </v-row>
            </v-col>
          </v-row> 
       </v-container>
       <v-row class="justify-center mx-auto text-center mt-2 mb-5">
        <v-btn
          color="purple"
          @click="e1 = 2"
        >
          Próximo
        </v-btn>

        <v-btn 
        @click="$emit('close')"
        text>
          Cancel
        </v-btn>
       </v-row>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-container style="height: 300px;">
          <v-row class="justify-center mx-auto text-center">
            <v-col class="justify-center mx-auto text-center">
              <v-row class="justify-left mx-auto text-center mb-2">  
                <h5>Saldo:</h5>
                <h5 v-if="valor_total > 0" class="box-input2">{{valor_total}}</h5>
                <h5 class="box-input2" v-else>R$ 0,00</h5>
              </v-row>
              <v-row class="box-input mb-2"> 
              <h3 class="justify-left mx-auto text-center">TED:</h3>
                <v-text-field dark
                type="tel" 
                label="CPF ou CNPJ" 
                v-mask="maskCpfCnpj()"
                :rules="[v => v.length >= 14 || 'CPF ou CNPJ são obrigatórios']" 
                v-model="transferir.CpfCnpj" required></v-text-field>
                
              </v-row>
              <hr>
              <v-row class="justify-center mx-auto text-center mt-5"> 
                <v-col>
                  <v-select 
                  v-model="transferir.banco" :items="bancos"
                 :item-text="getFieldText" 
                  label="Banco"                              
                  ></v-select>
                
                
                <v-text-field dark 
                type="text" 
                label="Nome da conta" 
                v-model="transferir.nome" 
                required>
                </v-text-field>
                </v-col>

              </v-row>
            </v-col>
          </v-row> 
          <hr>
       </v-container>
        <v-row class="justify-center mx-auto text-center mt-5 mb-1">
          <v-btn
            color="purple"
            @click="e1 = 3"
          ><!-- e1 = 3-->
            Próximo
          </v-btn>

          <v-btn 
          @click="$emit('close')"
          text>
            Cancel
          </v-btn>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-container  class="justify-center mx-auto text-center" style="height: 300px;" v-if="sucess == false && loading == false">
          <v-row class="justify-center mx-auto text-center">
            <v-col class="justify-center mx-auto text-center">
              <v-row class="justify-left mx-auto text-center mb-2">  
                <h5>Saldo:</h5>
                <h5 v-if="valor_total >0" class="box-input2">{{valor_total}}</h5>
                <h5 class="box-input2" v-else>R$ 0,00</h5>
              </v-row>
              <v-row class="box-input"> 
              <h3 class="justify-left mx-auto text-center">TED:</h3>
                <v-select 
                  v-model="transferir.tipo" :items="contas"
                  label="Tipo Conta"                              
                  ></v-select>
              </v-row>
              <hr class="box-input">
              <v-row class="justify-center mx-auto text-center"> 
                <v-col class="justify-center mx-auto text-center">
                  <v-row class="justify-center mx-auto text-center"> 
                    <v-text-field dark 
                    type="number" outlined 
                    label="Agência" class="box-input mt-5"
                    v-model="transferir.agencia" 
                    required>
                    </v-text-field>
                  </v-row>
                  <v-row class="justify-center mx-auto text-center">
                    <v-text-field dark 
                    type="number" dense outlined
                    label="Conta" 
                    v-model="transferir.conta" class="box-conta"
                    required>
                    </v-text-field>
                    <v-text-field dark 
                    type="number" dense outlined
                    label="Digito"  class="box-input-digito ml-2"
                    v-model="transferir.digito" 
                    required>
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container style="height: 300px;" v-if="sucess == false && loading == true">
            <v-row 
                class="fill-height"
                align-content="center"
              justify="center"
              >
              <v-col class="justify-center mx-auto text-center"  cols="6">
               
                <h5>Transferindo..</h5>
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="8"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
       <v-container class="justify-center mx-auto text-center" style="height: 300px;" v-if="sucess == true && loading == false">
         <v-row class="justify-center mx-auto text-center mt-5 mb-5">
            <div class="mark-icon">
              <span class="mark-line mark-tip"></span>
              <span class="mark-line mark-long"></span>
              <div class="mark-circle"></div>
              <div class="mark-fix"></div>
            </div>
          </v-row>
          <v-row  class="justify-center mx-auto text-center mt-5 mb-5">
          <h3> Transferência realizada com sucesso</h3>
          </v-row>
        <v-btn
          color="purple"
          @click="clearAll(), $emit('close')"
        >
          Finalizar
        </v-btn>
       </v-container>
       <v-row class="justify-center mx-auto text-center mb-2" >
        <v-btn
          color="purple"
          @click="transferencia()"
          v-if="sucess == false"
        >
          Transferir
        </v-btn>

        <v-btn 
        @click="$emit('close')" v-if="sucess == false"
        text>
          Cancel
        </v-btn>
       </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</v-dialog>
</template>
<script>
import axios from "axios"
export default {
    props: ['visible', 'valor_total'],
    computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      },

    }
  },
    data () {
      return {
        e1: 1,
        bancos: {},
        close: false,
        loading: false,
        sucess: false,
        contas:["Conta Corrente", "Conta Poupança"],
        transferir:{
          valor: null,
          CpfCnpj: "",
          nome: null,
          banco: null, 
          tipo: null,
          conta: null,
          digito:null,
          agencia: null,
        }
      }
    },
    created() {
      this.getBancos()
    },
  methods:{
    maskCpfCnpj(){
      if(this.transferir.CpfCnpj.length <= 14){
        return '###.###.###-##'
      } else{
        return '##.###.###/####-##'
      }
    },
    getBancos(){
       axios
        .get("https://brasilapi.com.br/api/banks/v1")
        .then((res) => {
          this.bancos = res.data;
        })
       .catch((error) => {
          console.log(error);
        });
    },getFieldText (item)
    {
      return `${item.code} - ${item.name}`
    },
    teste(){
      console.log(this.transferir)
    },
    transferencia(){
      this.loading = true
      setTimeout(()=> { return this.sucess = true, this.loading = false},2000)
    },
    clearAll(){
      this.e1 = 1
      this.loading = false
      this.sucess = false
    }
  }
}
</script>
<style scoped>
.transfer-value{
  background-color: #fff;
  width: 150px;
  margin: auto;
  color: green;
  border-radius: 10px;
}
.box-input{
  max-width: 180px;
  margin: auto;
}
.box-input2{
  background-color: #fff;
  width: 100px;
  margin-left:5px ;
  color: green;
  border-radius: 10px;
}
.box-input-digito {
  max-width: 80px;
}
.box-conta{
  max-width: 110px
}
</style>
<style lang="scss" scoped>


.mark-icon{
	width: 80px;
	height: 80px;
	border-radius: 50%;
	border: 4px solid #7c3bbd;
	position: relative;
	box-sizing: content-box;
	
	&::before,&::after{
		content: '';
		height: 100px;
		position: absolute;
		transform: rotate(-45deg);
	}
	
	&::before{
		top: 3px;
		left: -2px;
		width: 30px;
		border-radius: 100px 0 0 100px; 

transform-origin: 100% 50%;
	}
	
	&::after{
		top: 0px;
		left: 30px;
		width: 60px;
		border-radius: 0 100px 100px 0; 
		transform-origin: 0 50%;
		animation: rotate-circle 4.25s ease-in; 
	}
	
	.mark-line{
		display: block;
		height: 5px;
		background-color: #7c3bbd;
		border-radius: 2px;
		position: absolute;
		z-index: 10;
		
		&.mark-tip{
			top: 46px;
			left: 14px;
			width: 25px;
			transform: rotate(45deg);
			animation: mark-line-tip 0.75s;
		}
		
		&.mark-long{
			top: 39px;
			right: 7.5px;
			width: 47px;
			transform: rotate(-45deg);
			animation: mark-line-long 0.75s;
		}
	}
	
	.mark-circle{
		width: 80px;
		height: 80px;
		border-radius: 50%;
		border: 4px solid #7c3bbd;
		position: absolute;
		top: -4px;
		left: -4px;
		z-index: 10;
	}
	
	.mark-fix{
		width: 5px;
		height: 85px;
		position: absolute;
		top: 8px;
		left: 26px;
		z-index: 2;
		transform: rotate(-45deg);
	}
}


@keyframes rotate-circle {
	//外圈
	0% {
		transform: rotate(-45deg);
	}
	5% {
		transform: rotate(-45deg);
	}
	12% {
		transform: rotate(-405deg);
	}
	100% {
		transform: rotate(-405deg);
	}
}

//打勾
@keyframes mark-line-tip {
	0% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	54% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	70% {
		width: 50px;
		left: -8px;
		top: 37px;
	}
	84% {
		width: 17px;
		left: 21px;
		top: 48px;
	}
	100% {
		width: 25px;
    left: 14px;
    top: 45px;
	}
}

@keyframes mark-line-long {
	0% {
		width: 0;
		right: 47px;
		top: 54px;
	}
	65% {
		width: 0;
		right: 47px;
		top: 54px;
	}
	84% {
		width: 55px;
		right: 0px;
		top: 35px;
	}
	100%{
		width: 47px;
		right: 7.5px;
		top: 39px;
	}
}
</style>