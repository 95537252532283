<template>
    <v-col cols="auto">
      <v-dialog
        v-model="show" 
        transition="dialog-top-transition"
        max-width="600"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
              color="#6dbfeb"
              dark
            >Valor disponível para antecipar</v-toolbar>
            <v-card-text>
              <div class="pa-12" >              
                  <h1 justify="center" v-if="valor_total > 0" align="center">R$ {{valor_total}} </h1>
                  <h4 align="center">Não encontramos nenhum valor disponível</h4>
                  <br>
                  <hr>
                  <p> Por medidas de <b>Segurança</b> as antecipações estão
                      sendo efetuadas somente com nossos atendentes.
                      <br> Entre em contato com um de nossos 
                      atendentes para efetuar a antecipação</p>
            </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
              >Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
</template>
<script>

export default{
    props: ['visible', 'valor_total'],
    computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      },

    }
  },

}
</script>
